<template>
  <BaseDialog :model-value="true" size-class="!max-w-6xl" @update:model-value="$emit('close')">
    <template #header> Search </template>
    <template #default>
      <div class="flex-col items-center space-y-2">
        <BaseSearchbox placeholder="Search" ref="search_input" v-model="searchText" />
        <div class="pt-2 text-sm font-medium leading-5">
          <div class="flex cursor-pointer items-center space-x-4">
            <div v-for="tab in searchTabs" :key="tab.id">
              <div
                class="flex-1 space-y-2 text-center"
                :class="
                  tab.id === 'companies' || tab.id === 'messages' ? 'w-28' : tab.id === 'people' ? 'w-24' : 'w-20'
                "
                @click="selectedSearchTab = tab.id"
              >
                <span
                  class="whitespace-nowrap px-1 text-sm hover:text-white"
                  :class="selectedSearchTab === tab.id ? '' : 'text-gray-400'"
                >
                  {{ tab.text }} ({{ searchCount(tab.id) }})
                </span>
                <div :class="selectedSearchTab == tab.id ? 'border-b border-blue-600' : ''"></div>
              </div>
            </div>
          </div>
          <div class="border-b border-gray-800"></div>
        </div>
        <div class="flex items-center space-x-3 px-2 pt-1">
          <span class="text-xs font-medium leading-4">Filters</span>
          <div class="flex w-full items-center justify-between text-xs font-medium leading-4">
            <div class="flex w-full flex-wrap">
              <div
                v-for="filter in filters"
                class="relative mr-3 cursor-pointer select-none rounded bg-gray-800 px-2 py-1 text-xs font-medium duration-100 hover:text-gray-100"
                :key="filter.id"
                :class="
                  (filter.id == 'people' && filterPeoples.length) ||
                  (filter.id == 'companies' && filterCompanies.length)
                    ? 'bg-blue-900 text-gray-100 hover:bg-blue-800'
                    : 'text-gray-400 hover:bg-gray-700'
                "
                @click.self="
                  selectedSearchFilter = filter.id;
                  filter.opened = !filter.opened;
                "
              >
                <div
                  class="flex items-center"
                  @click="
                    selectedSearchFilter = filter.id;
                    filter.opened = !filter.opened;
                    $emit('click');
                  "
                >
                  {{ filter.title }}
                  <ChevronDownIcon class="ml-1 h-4 w-4 text-gray-400" />
                </div>
                <ChatSearchFilter
                  v-if="selectedSearchFilter == 'companies' && filter.id === 'companies' && filterOpened"
                  dropdown-class="left-0"
                  :items="filterItems"
                  :key="`companies_filter`"
                  :dropdown-width="'10rem'"
                  :search="true"
                  :multiple="true"
                  :selected="filterCompanies"
                  @removed="filterCompanies = filterCompanies.filter(x => x !== $event)"
                  @selected="filterCompanies.push($event)"
                  @clear="filterCompanies = []"
                  @close="closeFilter"
                />
                <ChatSearchFilter
                  v-if="selectedSearchFilter == 'chat_type' && filter.id === 'chat_type' && filterOpened"
                  dropdown-class="left-0"
                  :key="`chat_type_filter`"
                  :items="filterItems"
                  :dropdown-width="'10rem'"
                  :multiple="false"
                  :selected="[filterChatType]"
                  @selected="filterChatType = $event"
                  @clear="filterChatType = ''"
                  @close="closeFilter"
                />
                <ChatSearchFilter
                  v-if="selectedSearchFilter == 'people' && filter.id === 'people' && filterOpened"
                  dropdown-class="left-0"
                  :items="filterItems"
                  :key="`people_filter`"
                  :dropdown-width="'10rem'"
                  :search="true"
                  :multiple="true"
                  :selected="filterPeoples"
                  @removed="filterPeoples = filterPeoples.filter(x => x !== $event)"
                  @selected="filterPeoples.push($event)"
                  @clear="filterPeoples = []"
                  @close="closeFilter"
                />
                <ChatSearchFilter
                  v-if="selectedSearchFilter == 'chat_rooms' && filter.id === 'chat_rooms' && filterOpened"
                  dropdown-class="left-0"
                  :key="`chat_rooms_filter`"
                  :items="filterItems"
                  :dropdown-width="'10rem'"
                  :search="true"
                  :multiple="true"
                  :selected="filterChatRooms"
                  @removed="filterChatRooms = filterChatRooms.filter(x => x !== $event)"
                  @selected="filterChatRooms.push($event)"
                  @clear="filterChatRooms = []"
                  @close="closeFilter"
                />
                <ChatSearchFilter
                  v-if="selectedSearchFilter == 'date' && filter.id === 'date' && filterOpened"
                  dropdown-class="left-0"
                  :key="`date_filter`"
                  :items="filterItems"
                  :dropdown-width="filterDate === 'custom_range' ? '12rem' : '10rem'"
                  :multiple="false"
                  :selected="[filterDate]"
                  :selected-date-range="filterDateRange"
                  @selected="filterDate = $event"
                  @selected-date-range="filterDateRange = $event"
                  @clear="
                    filterDate = '';
                    filterDateRange = [];
                  "
                  @close="closeFilter"
                />
                <ChatSearchFilter
                  v-if="selectedSearchFilter == 'company_type' && filter.id === 'company_type' && filterOpened"
                  dropdown-class="left-0"
                  :key="`company_type_filter`"
                  :items="filterItems"
                  :dropdown-width="'10rem'"
                  :multiple="true"
                  :search="true"
                  :selected="filterCompanyTypes"
                  @selected="filterCompanyTypes.push($event)"
                  @removed="filterCompanyTypes = filterCompanyTypes.filter(x => x !== $event)"
                  @clear="filterCompanyTypes = []"
                  @close="closeFilter"
                />
              </div>
            </div>
            <div class="relative" v-if="selectedSearchTab">
              <div
                class="flex cursor-pointer items-center justify-between text-gray-400 hover:text-white"
                @click="
                  showSortingDropdown = !showSortingDropdown;
                  $emit('click');
                "
              >
                <span class="whitespace-nowrap text-xs font-medium leading-4">
                  {{ sortFilters[selectedSearchTab].find(x => x.selected).text }}
                </span>
                <ChevronDownIcon class="ml-1 h-5 w-5 text-gray-400 hover:text-white" />
              </div>
              <DropdownBase
                v-if="showSortingDropdown"
                custom-classes="mr-1"
                dropdown-width="7rem"
                :items="sortFilters[selectedSearchTab]"
                @clicked="
                  setSelectedSorting($event);
                  showSortingDropdown = false;
                "
              />
            </div>
          </div>
        </div>
        <div class="border-b border-gray-800"></div>
        <div
          class="h-96"
          :class="[
            loading || (selectedSearchTab && !searchCount(selectedSearchTab)) > 0
              ? 'overflow-y-hidden'
              : 'overflow-y-auto'
          ]"
          @scroll="handleScroll"
        >
          <div
            v-if="loading && !messages.length > 0 && selectedSearchTab === 'messages'"
            class="h-full space-y-1 px-1 py-3"
          >
            <LoadTemplatesSearchMessenger v-for="item in 7" :key="item" />
          </div>
          <div
            v-else-if="selectedSearchTab && !searchCount(selectedSearchTab) > 0"
            class="flex h-full w-full flex-col items-center justify-center space-y-2 py-5 text-center"
          >
            <ExclamationCircleIcon class="h-9 w-9 text-gray-400" />
            <span class="text-base font-semibold leading-6">No Results Yet</span>
            <span class="text-sm font-medium leading-5 text-gray-400">Search for results above</span>
          </div>
          <div v-else-if="selectedSearchTab == 'messages' && messages.length > 0" class="space-y-3 px-1 py-4">
            <div v-for="msg in filteredMessages" :key="msg.id">
              <div class="flex items-center justify-between">
                <div class="flex items-center space-x-2 px-1 text-xs text-gray-400">
                  <div class="h-9 w-9">
                    <ChatImage
                      size="sm"
                      :item="getChannelByCid(msg.channel.cid)"
                      :channel-name="getChannelName(getChannelByCid(msg.channel.cid))"
                      :is-channel="true"
                      :show-online="true"
                    ></ChatImage>
                  </div>
                  <div class="flex flex-col space-y-0.5">
                    <div class="flex items-center space-x-2">
                      <BaseTooltip position="top-end">
                        <template #target>
                          <p
                            class="line-clamp-1 cursor-pointer text-sm font-semibold leading-4 text-white"
                            @click="$emit('find-channel', getChannelByCid(msg.channel.cid))"
                          >
                            {{ truncate(getChannelName(getChannelByCid(msg.channel.cid)), 80) }}
                          </p>
                        </template>
                        <template #default>
                          {{ getChannelName(getChannelByCid(msg.channel.cid)) }}
                        </template>
                      </BaseTooltip>
                      <p class="whitespace-nowrap text-right text-xs leading-none text-gray-400">
                        {{ toChatTime(msg.created_at) }}
                      </p>
                    </div>
                    <span
                      v-html="msg.user.name + ': ' + styledMessage(msg.text)"
                      class="line-clamp-4 break-all text-xs font-normal leading-4 text-gray-400"
                    ></span>
                  </div>
                </div>
                <div
                  class="flex cursor-pointer rounded bg-gray-800 p-1 py-1.5"
                  @click="
                    $emit('find-message-in-channel', { id: msg.id, channelId: msg.channel.cid });
                    $emit('close');
                  "
                >
                  <span class="whitespace-nowrap px-3 text-xs font-medium leading-4 text-gray-400">View More</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="selectedSearchTab == 'chat_rooms'" class="space-y-3 px-1 py-4">
            <div v-for="channel in searchedChannels" :key="channel.id" class="flex w-full items-center justify-between">
              <div class="flex items-center space-x-2">
                <ChatImage
                  size="sm"
                  :item="channel"
                  :channel-name="getChannelName(channel)"
                  :is-channel="true"
                  :show-online="true"
                ></ChatImage>
                <div class="flex flex-col space-y-0.5">
                  <BaseTooltip position="top-end">
                    <template #target>
                      <div class="text-sm font-medium leading-5">
                        {{ truncate(getChannelName(channel), 80) }}
                      </div>
                    </template>
                    <template #default>
                      {{ getChannelName(channel) }}
                    </template>
                  </BaseTooltip>
                  <div class="text-xs font-normal leading-4 text-gray-400">{{ channel.data.member_count }} Members</div>
                </div>
              </div>
              <div
                class="flex cursor-pointer rounded bg-gray-800 p-1 px-2 py-1.5"
                @click="
                  $emit('findChannel', channel);
                  $emit('close');
                "
              >
                <span class="px-2 text-xs font-medium leading-4 text-gray-400">Go to Chat</span>
              </div>
            </div>
          </div>
          <div v-else-if="selectedSearchTab == 'people'" class="space-y-3 px-1 py-4">
            <div v-for="user in searchedUsers" :key="user.id" class="flex w-full items-center justify-between">
              <BaseUser
                :user="user"
                :title="user.name"
                :sub-text="getUserSubtitle(user)"
                :max-text-length="70"
                :max-title-length="80"
              >
                <template #image>
                  <ChatImage size="sm" :show-online="true" :item="user"> </ChatImage>
                </template>
              </BaseUser>
              <div
                class="flex cursor-pointer rounded bg-gray-800 p-1 py-1.5 hover:bg-gray-700"
                @click="
                  $emitter.$emit('send-dm', user);
                  $emit('close');
                "
              >
                <span class="px-4 text-xs font-medium leading-4 text-gray-400"> Message </span>
              </div>
            </div>
          </div>
          <div v-else-if="selectedSearchTab == 'companies'" class="space-y-3 px-1 py-4">
            <div
              v-for="company in searchedCompanies"
              :key="company.name"
              class="flex w-full items-center justify-between"
            >
              <BaseUser :title="company.name" :sub-text="`${company.category}`" :max-text-length="70">
                <template #image>
                  <ChatImage :item="company" size="sm"></ChatImage>
                </template>
              </BaseUser>
              <div
                class="flex cursor-pointer rounded bg-gray-800 p-1 py-1.5 hover:bg-gray-700"
                @click="
                  $emitter.$emit('show:create-channel-modal', company.name);
                  $emit('close');
                "
              >
                <span class="px-4 text-xs font-medium leading-4 text-gray-400"> Message </span>
              </div>
            </div>
          </div>
          <div v-if="loading && messages.length > 0" class="flex justify-center">
            <BaseLoader />
          </div>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import debounce from 'lodash/debounce';
import BaseUser from './ChatBaseUser.vue';
import ChatImage from './ChatImage.vue';
import ChatSearchFilter from './ChatSearchFilter.vue';
import { DateTime } from 'luxon';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import uniqBy from 'lodash/uniqBy';
import DropdownBase from '@/components/dropdown/DropdownBase.vue';
import LoadTemplatesSearchMessenger from '@/components/load_templates/LoadTemplatesSearchMessenger.vue';
import orderBy from 'lodash/orderBy';
import { useMapGetter } from '@/store/map-state';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';

import { useStore } from 'vuex';
import { getChannelName, getCompaniesByCategory, getUserSubtitle } from '@/composeables/chat';
import { truncate, toChatTime } from '@/composeables/filters';
import useEmitter from '@/composeables/emitter';

const { client, requestChannels, streamUsers, rejectedChannels } = useMapGetter();
const $emitter = useEmitter();
const $store = useStore();
const $emit = defineEmits(['find-message-in-channel', 'findChannel', 'close']);

const props = defineProps({
  channels: { type: Array, default: () => [] },
  searchTab: { type: String, default: null }
});

// FILTERS
const messageFilters = ref([
  { id: 'people', title: 'From', opened: false },
  { id: 'chat_rooms', title: 'Chat', opened: false },
  { id: 'date', title: 'Date', opened: false }
]);
const chatRoomFilters = ref([{ id: 'companies', title: 'Companies', opened: false }]);
const companyTypeFilters = ref($store.state.categories.company);
const peopleFilters = ref([{ id: 'companies', title: 'Companies', opened: false }]);
const companiesFilters = ref([{ id: 'company_type', title: 'Company Type', opened: false }]);
const selectedSearchFilter = ref('');
const dateFilterItems = ref([
  { id: 'today', name: 'Today' },
  { id: 'yesterday', name: 'Yesterday' },
  { id: 'this_week', name: 'This Week' },
  { id: 'this_month', name: 'This Month' },
  { id: 'custom_range', name: 'Custom Range' }
]);
const channelTypeFilters = ref([
  { id: 'pinned', name: 'Pinned' },
  { id: 'temporary', name: 'Temporary' },
  { id: 'archived', name: 'Archived' }
]);
const filterPeoples = ref([]);
const filterChatRooms = ref([]);
const filterCompanies = ref([]);
const filterCompanyTypes = ref([]);
const filterChatType = ref('');
const filterDate = ref('');
const filterDateRange = ref([]);
const sortFilters = ref({
  messages: [
    {
      id: 'latest',
      text: 'Latest',
      selected: true
    },
    {
      id: 'oldest',
      text: 'Oldest',
      selected: false
    }
  ],
  chat_rooms: [
    {
      id: 'new_chat',
      text: 'Newest Chat',
      selected: true
    },
    {
      id: 'old_chat',
      text: 'Oldest Chat',
      selected: false
    },
    {
      id: 'a_z',
      text: 'A-Z',
      selected: false
    },
    {
      id: 'z_a',
      text: 'Z-A',
      selected: false
    }
  ],
  people: [
    {
      id: 'most_relevant',
      text: 'Most Relevant',
      selected: true
    },
    {
      id: 'a_z',
      text: 'A-Z',
      selected: false
    },
    {
      id: 'z_a',
      text: 'Z-A',
      selected: false
    }
  ],
  companies: [
    {
      id: 'most_relevant',
      text: 'Most Relevant',
      selected: true
    },
    {
      id: 'a_z',
      text: 'A-Z',
      selected: false
    },
    {
      id: 'z_a',
      text: 'Z-A',
      selected: false
    }
  ]
});

const filters = computed(() => {
  let filtersData = [];
  if (selectedSearchTab.value === 'messages') {
    filtersData = messageFilters.value;
  } else if (selectedSearchTab.value === 'chat_rooms') {
    filtersData = chatRoomFilters.value;
  } else if (selectedSearchTab.value === 'people') {
    filtersData = peopleFilters.value;
  } else if (selectedSearchTab.value === 'companies') {
    filtersData = companiesFilters.value;
  }
  return filtersData;
});
const filterItems = computed(() => {
  let data = [];
  switch (selectedSearchFilter.value) {
    case 'people':
      data = streamUsers.value.map(user => {
        return { id: user.id, name: user.name, selected: false };
      });
      break;
    case 'chat_rooms':
      data = props.channels
        .filter(c => !rejectedChannels.value.includes(c.cid))
        .map(channel => {
          return {
            id: channel.cid,
            name: getChannelName(channel),
            selected: false
          };
        });
      break;
    case 'date':
      data = dateFilterItems.value;
      break;
    case 'companies':
      data = companies.value.map(c => {
        return { name: c.name, id: c.name, selected: false };
      });
      break;
    case 'chat_type':
      data = channelTypeFilters.value;
      break;
    case 'company_type':
      data = companyTypeFilters.value.map(filter => {
        return { id: filter.name.toLowerCase(), name: filter.name };
      });
      break;
  }
  return data;
});
const filterOpened = computed(() => {
  return filters.value.find(x => x.id === selectedSearchFilter.value).opened;
});

function closeFilter() {
  filters.value.find(x => x.id === selectedSearchFilter.value).opened = false;
}

watch(
  () => selectedSearchFilter.value,
  (newVal, oldVal) => {
    filters.value.filter(x => x.id !== newVal).map(f => (f.opened = false));
  }
);

// SEARCH
const searchText = ref('');
const searchTabs = ref([
  { id: 'messages', text: 'Messages' },
  { id: 'chat_rooms', text: 'Chats' },
  { id: 'people', text: 'People' },
  { id: 'companies', text: 'Companies' }
]);
const searchedChannels = ref([]);
const noFound = ref(true);
const showSortingDropdown = ref(false);
function searchChannels() {
  let channelsData = props.channels.filter(c =>
    searchText.value.trim().length > 0
      ? getChannelName(c) &&
        getChannelName(c).toLowerCase().includes(searchText.value.trim().toLowerCase()) &&
        !rejectedChannels.value.includes(c.cid) &&
        !requestChannels.value.includes(c.cid)
      : false
  );
  if (filterCompanies.value.length > 0 && channelsData.length > 0) {
    channelsData = channelsData.filter(c =>
      filterCompanies.value.some(comp => c.data.created_by.companies.includes(comp))
    );
  }
  if (['a_z', 'z_a'].includes(selectedSorting.value)) {
    channelsData = orderBy(
      channelsData,
      function (o) {
        return getChannelName(o);
      },
      [selectedSorting.value == 'a_z' ? 'asc' : 'desc']
    );
  } else if (['new_chat', 'old_chat'].includes(selectedSorting.value)) {
    channelsData = orderBy(
      channelsData,
      o => {
        return new Date(o.data.created_at).getTime();
      },

      [selectedSorting.value == 'old_chat' ? 'asc' : 'desc']
    );
  }
  searchedChannels.value = channelsData;
  noFound.value = searchedChannels.value.length < 1;
}

const searchedUsers = ref([]);
function searchUsers() {
  let users = streamUsers.value.filter(user =>
    user.name && searchText.value.trim().length > 0
      ? user.name &&
        user.name.toLowerCase().includes(searchText.value.trim().toLowerCase()) &&
        user.id !== client.value.user.id
      : false
  );
  if (filterCompanies.value.length > 0 && users.length > 0) {
    users = users.filter(u => filterCompanies.value.some(comp => u.companies && u.companies.includes(comp)));
  }
  noFound.value = users.length < 1;
  if (['a_z', 'z_a'].includes(selectedSorting.value)) {
    users = orderBy(
      users,
      function (o) {
        return o.name;
      },
      [selectedSorting.value == 'a_z' ? 'asc' : 'desc']
    );
  }
  searchedUsers.value = users;
}

const currentPage = ref(null);
const lastPage = ref(false);
const selectedSearchTab = ref(props.searchTab || 'messages');
function updateSearchByTab() {
  if (selectedSearchTab.value === 'messages') {
    messages.value = [];
    currentPage.value = null;
    lastPage.value = false;
    searchMessages();
  } else if (selectedSearchTab.value === 'chat_rooms') {
    searchChannels();
  } else if (selectedSearchTab.value === 'people') {
    searchUsers();
  } else if (selectedSearchTab.value === 'companies') {
    searchCompanies();
  }
}
const debounceSearch = ref('');
const updateSearch = debounce(function () {
  debounceSearch.value = searchText.value;
  messages.value = [];
  currentPage.value = null;
  lastPage.value = false;
  searchMessages();
  searchChannels();
  searchUsers();
  searchCompanies();
}, 500);

function resetSearcedData() {
  searchedCompanies.value = [];
  searchedChannels.value = [];
  messages.value = [];
  searchedUsers.value = [];
  selectedSearchFilter.value = '';
}

watch(
  () => searchText.value,
  (newVal, oldVal) => {
    if (newVal !== '' || (newVal === '' && oldVal !== '')) {
      updateSearch();
    }
  }
);
watch(
  () => selectedSearchTab.value,
  () => {
    showSortingDropdown.value = false;
    if (selectedSearchTab.value !== 'messages') {
      filterCompanies.value = [];
      chatRoomFilters.value[0].title = 'Companies';
      peopleFilters.value[0].title = 'Companies';
    }
  }
);
watch(
  () => filterChatRooms.value,
  () => {
    updateSearchByTab();
  },
  { deep: true }
);
watch(
  () => filterPeoples.value,
  () => {
    if (filterPeoples.value.length == 1) {
      let item = filterItems.value.find(x => x.id == filterPeoples.value[0]);
      messageFilters.value[0].title = item.name;
    } else if (filterPeoples.value.length > 1) {
      messageFilters.value[0].title = filterPeoples.value.length + ' People';
    } else {
      messageFilters.value[0].title = 'From';
    }
    updateSearchByTab();
  },
  { deep: true }
);
watch(
  () => filterCompanies.value,
  () => {
    if (filterCompanies.value.length == 1) {
      let item = filterCompanies.value.find(x => x == filterCompanies.value[0]);
      chatRoomFilters.value[0].title = item;
      peopleFilters.value[0].title = item;
    } else if (filterCompanies.value.length > 1) {
      chatRoomFilters.value[0].title = filterCompanies.value.length + ' Companies';
      peopleFilters.value[0].title = filterCompanies.value.length + ' Companies';
    } else {
      chatRoomFilters.value[0].title = 'Companies';
      peopleFilters.value[0].title = 'Companies';
    }
    updateSearchByTab();
  },
  { deep: true }
);
watch(
  () => filterCompanyTypes.value,
  () => {
    updateSearchByTab();
  },
  { deep: true }
);
watch(
  () => filterChatType.value,
  () => {
    updateSearch();
  },
  { deep: true }
);

// MESSAGES
const messages = ref([]);
const filteredMessages = computed(() => {
  let msgs = [...messages.value];
  return uniqBy(msgs, function (o) {
    return o.id;
  });
});

const loading = ref(false);
async function searchMessages() {
  if (lastPage.value || searchText.value.trim().length === 0) {
    return;
  }
  loading.value = true;
  let messagesData = [];
  let channelFilters = [{ members: { $in: [client.value.user.id] }, joined: { $eq: true } }];
  let pagination = currentPage.value
    ? { sort: [{ created_at: selectedSorting.value === 'latest' ? -1 : 1 }], limit: 100, next: currentPage.value }
    : { sort: [{ created_at: selectedSorting.value === 'latest' ? -1 : 1 }], limit: 100 };
  if (filterChatRooms.value.length > 0) {
    channelFilters.push({ cid: { $in: filterChatRooms.value } });
  }
  let messageFilters = [{ text: { $autocomplete: searchText.value } }, { activity_status: { $eq: '' } }];
  if (filterPeoples.value.length > 0) {
    messageFilters.push({ 'user.id': { $in: filterPeoples.value } });
  }
  if (calculatedDateRange.value.length > 0 && !calculatedDateRange.value.includes(null)) {
    messageFilters.push({ created_at: { $gte: calculatedDateRange.value[0] } });
    messageFilters.push({ created_at: { $lte: calculatedDateRange.value[1] } });
  }
  messagesData = await client.value.search(
    {
      $and: channelFilters
    },
    {
      $and: messageFilters
    },
    pagination
  );
  if (messagesData.results.length > 0) {
    currentPage.value = messagesData.next;
    messagesData.results.map(x => messages.value.push(x.message));
    noFound.value = false;
    if (messagesData.results.length < 100) {
      lastPage.value = true;
    }
  } else {
    messages.value = [];
    noFound.value = true;
  }
  loading.value = false;
}

// COMPANIES
const companies = ref([]);
onMounted(() => {
  setTimeout(() => {
    companies.value = getCompaniesByCategory('All Companies', streamUsers.value);
  });
});
const searchedCompanies = ref([]);
function searchCompanies() {
  let companiesData = companies.value.filter(c =>
    searchText.value.trim().length > 0 ? c.name && c.name.toLowerCase().includes(searchText.value.toLowerCase()) : false
  );
  if (filterCompanyTypes.value.length > 0 && companiesData.length > 0) {
    companiesData = companiesData.filter(comp =>
      comp.category.length
        ? comp.category.split(', ').some(c => filterCompanyTypes.value.includes(c.toLowerCase()))
        : false
    );
  }
  if (['a_z', 'z_a'].includes(selectedSorting.value)) {
    companiesData = orderBy(
      companiesData,
      function (o) {
        return o.name;
      },
      [selectedSorting.value == 'a_z' ? 'asc' : 'desc']
    );
  }
  searchedCompanies.value = companiesData;
  noFound.value = searchedCompanies.value.length < 1;
}

// SEARCH COUNT
const searchCount = computed(() => {
  return id => {
    if (id == 'messages') {
      return filteredMessages.value.length;
    } else if (id == 'chat_rooms') {
      return searchedChannels.value.length;
    } else if (id == 'people') {
      return searchedUsers.value.length;
    } else if (id == 'companies') {
      return searchedCompanies.value.length;
    }
    return 0;
  };
});

// SELECTED SORT
const selectedSortText = computed(() => {
  return sortFilters.value[selectedSearchTab.value.toString()].find(s => s.id === selectedSorting.value)?.text;
});
const selectedSorting = computed(() => {
  return sortFilters.value[selectedSearchTab.value.toString()].find(s => s.selected)?.id;
});

function setSelectedSorting(id) {
  sortFilters.value[selectedSearchTab.value.toString()].forEach(s => {
    if (s.id === id) {
      s.selected = true;
    } else {
      s.selected = false;
    }
  });
  updateSearchByTab();
}

// CALCULATE RANGE
const calculatedDateRange = ref([]);

function calculateRange() {
  let range = [];
  switch (filterDate.value) {
    case 'today':
      range = [DateTime.utc().startOf('day').toISO(), DateTime.utc().endOf('day').toISO()];
      break;
    case 'yesterday':
      range = [
        DateTime.utc().minus({ days: 1 }).startOf('day').toISO(),
        DateTime.utc().minus({ days: 1 }).endOf('day').toISO()
      ];
      break;
    case 'this_week':
      range = [DateTime.utc().startOf('week').toISO(), DateTime.utc().endOf('week').toISO()];
      break;
    case 'this_month':
      range = [DateTime.utc().startOf('month').toISO(), DateTime.utc().endOf('month').toISO()];
      break;
    case 'custom_range':
      if (filterDateRange.value.length > 0) {
        range = [
          DateTime.fromFormat(filterDateRange.value[0], 'yyyy-MM-dd').setZone('UTC').startOf('day').toISO(),
          DateTime.fromFormat(filterDateRange.value[1], 'yyyy-MM-dd').setZone('UTC').endOf('day').toISO()
        ];
      }
      break;
  }
  calculatedDateRange.value = range;
}

watch(
  () => calculatedDateRange.value,
  () => {
    updateSearchByTab();
  },
  { deep: true }
);

watch(
  () => filterDate.value,
  (newVal, oldVal) => {
    if (newVal !== 'custom_range') {
      calculateRange();
    }
  }
);
watch(
  () => filterDateRange.value,
  () => {
    calculateRange();
  }
);

// OTHERS
const handleScroll = function (el) {
  if (
    el.target.offsetHeight + el.target.scrollTop >= el.target.scrollHeight - 200 &&
    !loading.value &&
    !lastPage.value &&
    selectedSearchTab.value == 'messages'
  ) {
    searchMessages();
  }
};

function getChannelByCid(cid) {
  return props.channels.find(c => c.cid === cid);
}

function styledMessage(text) {
  let msg = text;
  if (searchText.value) {
    searchText.value.split(' ').forEach(chunk => {
      msg = replacer(msg, chunk);
    });
  }
  return msg;
}

function replacer(str, replace) {
  const re = new RegExp(`(${replace})`, 'gi');
  return str.replaceAll(re, '<span class="text-gray-100 font-medium">$1</span>');
}
</script>
